import React from 'react'
import { category, NavigationObject } from '../../types'
import { categoryFormat } from './BlogWidget'
import Router, { useRouter } from 'next/router'
import { capitalizeFirstLetter, findParentElements } from '../../utils/helpers'

interface BlogFilterProps {
  type: string
  options: category[]
  styleClass?: string
  setCategory: (c :unknown) => void
  navPath: NavigationObject[]
  setNavigationPath: (n:unknown) => void
  openMenuItems: string[]
}

const BlogFilter = ({
    options,
    setCategory,
    setNavigationPath,
    openMenuItems,
}: BlogFilterProps) => {
    const nestedList = (category_options: category[]) => {
        const router = useRouter()
        const { locale } = router
        // console.log('category_options', category_options)
        const getOptionName = (option) => {
            switch (locale) {
            case 'de':
                return option.optionName_de || option.optionName
            case 'fr':
                return option.optionName_fr || option.optionName
            case 'it':
                return option.optionName_it || option.optionName
            case 'en':
            default:
                return option.optionName
            }
        }
        return category_options?.map((option, key) => {
            const localeOptionName = getOptionName(option)
            return (
                <li
                    key={key}
                    onClick={(e) => {
                        e.stopPropagation()
                        let tempPath = '/blog'
                        setCategory({ optionName: option.optionName, id: option.id })
                        const parents = findParentElements(options, option.optionName)
                        const tempNav = []
                        parents.forEach((el) => {
                            if (el.optionName !== 'Categories') {
                                tempNav.push({ optionName: el.optionName, id: el.id })
                                tempPath = tempPath + '/' + categoryFormat(el.optionName)
                            }
                        })

                        tempPath = tempPath + '/' + categoryFormat(option.optionName)

                        if (option.optionName !== 'Categories') {
                            setNavigationPath([...tempNav, { optionName: option.optionName, id: option.id }])
                            Router.replace(tempPath).then(() => console.debug(('BlogFilter.tsx: Router.replace')),err=>console.error('BlogFilter.tsx: Router.replace',err))
                        }
                    }}
                >
                    <label>{`${capitalizeFirstLetter(localeOptionName)}`}</label>

                    {option.category_options && option.category_options?.length !== 0 && (
                        <>
                            <i className="blog-dropdown-icon fas fa-caret-down"></i>
                            <ul
                                className={
                                    openMenuItems.indexOf(option.id) > -1 ? 'open nested-filters' : 'nested-filters'
                                }
                                id={option.id}
                            >
                                {nestedList(option.category_options)}
                            </ul>
                        </>
                    )}
                </li>
            )
        })
    }

    return (
        <div className="col-sm-12">
            <ul className="blog-filters">{nestedList(options)}</ul>
        </div>
    )
}
export default BlogFilter

