export const flattenOptions = (data) => {
    let result = []

    data.forEach((item) => {
        result.push(item.optionName)

        if (item.category_options && item.category_options.length > 0) {
            result = result.concat(flattenOptions(item.category_options))
        }
    })

    return result
}
export const flattenOptionsWithOptionId = (data) => {
    let result = []

    data.forEach((item) => {
        result.push({ option_name: item.optionName, id: item.id })

        if (item.category_options && item.category_options.length > 0) {
            result = result.concat(flattenOptionsWithOptionId(item.category_options))
        }
    })

    return result
}
export const findParentElements = (arr, optionName, parents = []) => {
    for (let i = 0; i < arr.length; i++) {
        const element = arr[i]
        if (element.optionName === optionName) {
            return parents
        } else if (element.category_options && element.category_options.length > 0) {
            const result = findParentElements(element.category_options, optionName, [...parents, element])
            if (result) {
                return result
            }
        }
    }
    return null
}

export const capitalizeFirstLetter = (label: string) => {
    const arr = label?.toString().split(' ')
    for (let i = 0; i < arr?.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    const capitalizeString = arr?.join(' ')
    return capitalizeString
}
