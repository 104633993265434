import React, { useState, useEffect } from 'react'
import { BlogPostProps, category, NavigationObject, MetaTagProps } from '../../src/types'
import BlogWidget, { categoryFormat } from '../../src/components/Blog/BlogWidget'
import { BlogCategoriesTag, BlogMetaTags } from '../../src/components/Metatags/tags'
import { getGraphcmsBlogPosts, getBlogPostCategories } from '../../src/utils/graphcms-utils'
import Navbar from '../../src/components/theme/Layouts/NavbarSimplybook'
import Footer from '../../src/components/theme/Layouts/Footer'
import MemoizedFilter from '../../src/components/Blog/BlogFilter'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { gql } from '@apollo/client'
import client from '../../apolloClient'
import _ from 'lodash'
import {
    capitalizeFirstLetter,
    findParentElements,
    flattenOptions,
    flattenOptionsWithOptionId,
} from '../../src/utils/helpers'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import ErrorComponent from '../../src/components/ErrorComponent'

const Blog = ({ BlogPostWidget, categoryData }) => {
    // console.log(categoryData)
    const [blogCategory, setBlogCategory] = useState<NavigationObject>(null)
    const [blogNavigation, setBlogNavigation] = useState<NavigationObject[]>([])
    const [showArticleList, setShowArticleList] = useState(true)
    const [articleIndex, setArticleIndex] = useState(null)
    const [articleMetaData, setArticleMetaData] = useState<MetaTagProps>()
    const [openMenuItems, setOpenMenuItems] = useState(['cli32ey5056pv0bl6k1g4ozj0'])
    const router = useRouter()
    const { locale } = router
    const setNavigation = (category: string) => {
        let navigation = ''
        for (let i = 0; i < blogNavigation.length; i++) {
            navigation =
        navigation + '/' + blogNavigation[i].optionName.toLowerCase().split(' ').join('-')
            if (blogNavigation[i].optionName === category) return navigation
        }
        return navigation
    }

    const createMarkup = (message) => {
        return { __html: message }
    }
    const getLanguage = (locale) => {
        switch (locale) {
        case 'it':
            return 'Italian'
        case 'fr':
            return 'French'
        case 'de':
            return 'German'
        }
    }
    const getLocaledField = (field, currentArticle) => {
        return locale === 'en'
            ? currentArticle[field]
            : currentArticle[`${field}_${locale}`] || currentArticle[field]
    }
    const isCategory = (categoryName: string, categoryArray) => {
        if (categoryArray) {
            const categoryArrayFlatten = flattenOptions(categoryArray)
            categoryArrayFlatten?.forEach((optionName) => {
                if (categoryFormat(optionName) === categoryFormat(categoryName)) {
                    setShowArticleList(true)
                    setBlogCategory({ optionName: optionName })
                    return true
                }
            })
        }
        return false
    }

    const isArticle = (article: string) => {
        BlogPostWidget?.forEach((post, index) => {
            if (post.slug.toLowerCase() === article.toLowerCase()) {
                setArticleIndex(index)
                setShowArticleList(false)
                const currentArticle = BlogPostWidget[index]
                // console.log(currentArticle)
                setArticleMetaData({
                    title: getLocaledField('title', currentArticle),
                    slug: currentArticle.slug,
                    description: getLocaledField('metaDescription', currentArticle),
                    featureImageTitle: getLocaledField('featureImageTitle', currentArticle),
                    featureImageDescription: getLocaledField('featureImageDescription', currentArticle),
                    openGraphTitle: getLocaledField('metaOpenGraphTitle', currentArticle),
                    openGraphDescription: getLocaledField('metaOpenGraphDescription', currentArticle),
                    openGraphImage: currentArticle.featured_image.url,
                    openGraphImageAlt: getLocaledField('alttext', currentArticle),
                })
            }
        })
    }

    const createBreadcrumb = () => {
        const categoryArray = router.asPath.split('/').map((p) => {
            return p.replace(/\?.*$/, '')
        })
        categoryArray.splice(0, 2)
        if (categoryArray.length >= 1) {
            const routerpath = []
            categoryArray.forEach((category, index) => {
                routerpath.push({
                    optionName: capitalizeFirstLetter(category.split('-').join(' ')),
                    id: index,
                })
            })
            setBlogNavigation([...routerpath])
            if (categoryData) {
                const openArr = []

                Object.assign(openArr, openMenuItems)
                const flattenedOptions = flattenOptionsWithOptionId(categoryData)

                categoryArray.forEach((el) => {
                    const category = _.find(flattenedOptions, (option) => {
                        return categoryFormat(option.option_name) == el
                    })
                    if (category !== undefined) openArr.push(category.id)
                })
                setOpenMenuItems(openArr)
            }
        }
    }
    // const categoryFound = (categoryArray, searchId) => {
    //   categoryArray?.forEach((cat) => {
    //     if (cat.id === searchId) return true
    //     else if (cat.category_options) return categoryFound(cat.category_options, searchId)
    //   })
    //   return false
    // }

    const showBlog = (blogCategories) => {
        let showArticle = false

        if (blogCategory) {
            blogCategories.forEach((cat) => {
                const parents = _.map(findParentElements(categoryData, cat.optionName), 'optionName')

                if (
                    cat.optionName === blogCategory.optionName ||
          parents.includes(blogCategory.optionName)
                ) {
                    showArticle = true
                }
            })
        }

        return showArticle
    }

    useEffect(() => {
        const categoryArray = router.asPath.split('/').map((p) => {
            return p.replace(/\?.*$/, '')
        })
        categoryArray.splice(0, 2)
        if (categoryArray.length >= 1) {
            if (isCategory(categoryArray[categoryArray.length - 1], categoryData)) {
                setShowArticleList(true)
            }
            isArticle(categoryArray[categoryArray.length - 1])

            createBreadcrumb()
        }
    }, [showArticleList, router])

    useEffect(() => {}, [blogNavigation])
    return (
        <ErrorBoundary errorComponent={({ error }) => ErrorComponent('blog category', error)}>
            <>
                {!showArticleList ? <BlogMetaTags metaContent={articleMetaData} /> : <BlogCategoriesTag />}
                <Navbar withoutBanner={false} />

                {/* <a href="https://www.simplybook.com/blogs"> VisitBlog</a> */}
                {showArticleList ? (
                    <div className="container-fluid px-5 top-screen">
                        <div className="row container_width">
                            <div className="col-lg-9">
                                <div className="blog-navigation">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li
                                                className="breadcrumb-item"
                                                onClick={() => {
                                                    router.replace('/blog').then(() => router.reload())
                                                }}
                                                aria-current="page"
                                            >
                                                <h1>Simplybook - blog</h1>
                                            </li>
                                            {blogNavigation.length !== 0 &&
                        blogNavigation.map((item, index) => (
                            <li
                                key={item.id}
                                className={`breadcrumb-item ${
                                    index + 1 === blogNavigation.length ? 'active' : ''
                                }`}
                                onClick={() => {
                                    setBlogCategory(item)
                                    router.push(`/blog${setNavigation(item.optionName)}`)
                                }}
                                aria-current="page"
                            >
                                <h1>{capitalizeFirstLetter(item.optionName?.split('-').join(' '))}</h1>
                            </li>
                        ))}
                                        </ol>
                                    </nav>
                                </div>

                                <div style={{ marginTop: '20px' }} className="container">
                                    <div className="row">
                                        {BlogPostWidget?.map((item, index: number) => {
                                            return blogNavigation.length !== 0 && item.categories ? (
                                                showBlog(item.categories) && (
                                                    <BlogWidget
                                                        key={item.id || index}
                                                        blogPost={item}
                                                        categories={categoryData}
                                                        currentLocale={locale}
                                                    />
                                                )
                                            ) : (
                                                <BlogWidget
                                                    key={item.id || index}
                                                    blogPost={item}
                                                    categories={categoryData}
                                                    currentLocale={locale}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3" style={{ paddingTop: '110px' }}>
                                <MemoizedFilter
                                    type={'Categories'}
                                    options={categoryData}
                                    styleClass="header-button"
                                    setCategory={setBlogCategory}
                                    navPath={blogNavigation}
                                    setNavigationPath={setBlogNavigation}
                                    openMenuItems={openMenuItems}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="blog-content">
                        <div className="blog-navigation overflow-eclipse">
                            <nav>
                                <ol className="breadcrumb overflow-eclipse">
                                    <li
                                        className="breadcrumb-item"
                                        onClick={() => router.replace('/blog').then(() => router.reload())}
                                        aria-current="page"
                                    >
                                        <h2>Simplybook - blog</h2>
                                    </li>
                                    {blogNavigation.length !== 0 &&
                    blogNavigation.map((item, index) => (
                        <li
                            key={index}
                            className={`breadcrumb-item ${
                                index + 1 === blogNavigation.length ? 'active' : ''
                            }`}
                            onClick={() =>
                                router
                                    .replace(`/blog${setNavigation(item.optionName)}`)
                                    .then(() => router.reload())
                            }
                            aria-current="page"
                        >
                            <h1>{capitalizeFirstLetter(item.optionName?.split('-').join(' '))}</h1>
                        </li>
                    ))}
                                </ol>
                            </nav>
                        </div>
                        <Image
                            className="blog-img"
                            src={BlogPostWidget[articleIndex]?.featured_image.url}
                            alt={BlogPostWidget[articleIndex]?.alttext}
                            width="640"
                            height="424"
                        />
                        <h1 style={{ fontSize: '32px' }}>
                            {BlogPostWidget[articleIndex] &&
                (locale === 'en'
                    ? BlogPostWidget[articleIndex].title
                    : BlogPostWidget[articleIndex][`title_${locale}`]
                        ? BlogPostWidget[articleIndex][`title_${locale}`]
                        : BlogPostWidget[articleIndex].title)}
                        </h1>
                        <div
                            className="text-left my-3 my-md-5"
                            dangerouslySetInnerHTML={createMarkup(
                                BlogPostWidget[articleIndex] && locale === 'en'
                                    ? BlogPostWidget[articleIndex].content.html
                                    : BlogPostWidget[articleIndex][`content_${locale}`] &&
                      BlogPostWidget[articleIndex][`content_${locale}`] ===
                        `Translation to ${getLanguage(locale)}`
                                        ? BlogPostWidget[articleIndex][`content_${locale}`].html
                                        : BlogPostWidget[articleIndex].content.html
                            )}
                        />
                    </div>
                )}
                <Footer />
            </>
        </ErrorBoundary>
    )
}

export async function getStaticPaths() {
    const BLOGS_BY_NAME_QUERY = gql`
    query BlogPostsQuery {
      blogPosts {
        slug
      }
    }
  `
    let paths: Array<string | { params: { [key: string]: string } }> = []
    try {
        const apiResponse = await client.query({
            query: BLOGS_BY_NAME_QUERY,
        })
        const blogs = await apiResponse?.data?.blogPosts
        //const locales = ['en', 'de', 'fr', 'it']
        paths = await blogs?.map(() => {
            const categoryArray = []

            return {
                params: {
                    category: [...categoryArray],
                },
            }
        })
    } catch (error) {
        console.error('grapcms api error', error)
    }
    return {
        paths,
        fallback: true,
    }
}

export async function getStaticProps() {
    const BlogPostWidget: BlogPostProps[] = await getGraphcmsBlogPosts()
    const categoryData: category[] = await getBlogPostCategories()
    return {
        props: {
            BlogPostWidget,
            categoryData,
        },
        revalidate: 60,
    }
}

export default Blog
