import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { findParentElements } from '../../utils/helpers'

export const categoryFormat = (category: string) => {
    return category?.split(' ').join('-').toLowerCase()
}

const BlogWidget = ({ blogPost, categories, currentLocale }) => {
    const { t, ready } = useTranslation()
    const [path, setPath] = useState('')
    useEffect(() => {
        let tempPath = '/blog'
        if (blogPost.categories) {
            const mainCategory = blogPost.categories[0]
            const parents = findParentElements(categories, mainCategory.optionName)
            parents.forEach((el) => {
                tempPath =
          el.optionName !== 'Categories' ? tempPath + '/' + categoryFormat(el.optionName) : tempPath
            })
        }
        tempPath = tempPath + '/' + blogPost.slug
        setPath(tempPath)
    }, [])
    return (
        <div className="col-lg-4 col-md-6">
            {ready && (
                <Link href={path}>
                    <div className="col-lg-12 px-0">
                        <Image
                            style={{ borderRadius: '5px', width: '100%', height: 'auto' }}
                            src={blogPost.featured_image.url}
                            alt={blogPost.alttext}
                            width={350}
                            height={450}
                        />
                        <div className="blogDiv">
                            <h2 className="black-font">
                                {blogPost &&
                  (currentLocale === 'en'
                      ? blogPost.title
                      : blogPost[`title_${currentLocale}`]
                          ? blogPost[`title_${currentLocale}`]
                          : blogPost.title)}
                            </h2>
                            <p>
                                {blogPost &&
                  (currentLocale === 'en'
                      ? blogPost.preview_text
                      : blogPost[`preview_text_${currentLocale}`]
                          ? blogPost[`preview_text_${currentLocale}`]
                          : blogPost.preview_text)}
                            </p>
                            <span style={{ color: 'blue', cursor: 'pointer' }}>{t('Read more')}</span>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    )
}

export default BlogWidget
